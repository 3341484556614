import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../components/navbar/Navbar'
import './Error.css';
import { errorbg } from '../../assets/img';


const Error = () => {
    const [count, setCount] = useState(5);
    const navigate = useNavigate();

    useEffect(() => {
        const countdownInterval = setInterval(() => {
            setCount((prevCount) => prevCount - 1);
        }, 1000);

        if (count === 0) {
            clearInterval(countdownInterval);
            navigate('/');
        }

        return () => clearInterval(countdownInterval);
    }, [count, navigate]);

    const backgroundStyle = {
        background: `url(${errorbg}) center/cover no-repeat`,
        height: '100%'
    };

    return (
        <div style={backgroundStyle}>
            <div className="error__page-contaniner">
                <Navbar></Navbar>
                <div className='error__page'>
                    <div className='error__page-box'>
                        <h1>A keresett oldal nem található!</h1>
                        <p>Vissza irányítás a főoldalra {count} másodperc múlva</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Error;
