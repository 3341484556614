import React from 'react';
import Navbar from '../../components/navbar/Navbar';
import Copyright from '../../components/copyright/Copyright';
import ContactForm from '../../components/contactform/ContantForm';
import { motion } from 'framer-motion';
import './Contact.css';
import { contacthero } from '../../assets/img';

const Contact = () => {

    const backgroundStyle = {
        background: `url(${contacthero}) center/cover no-repeat`,
        minHeight: '100vh'
    };

    return (
        <div className='contact__container'>
            <div style={backgroundStyle}>
                <div style={{ background: 'rgba(49, 49, 49, .75)', minHeight: '100vh', overflow: 'hidden' }}>
                    <Navbar />
                    <div className='contact__contactform-box'>
                        <motion.div
                            initial={{ opacity: 0, x: 100 }}
                            whileInView={{ x: 0, opacity: 1 }}
                            viewport={{ once: true }}
                            transition={{ ease: "easeOut", duration: .7 }}
                            className='contact__contactform'
                        >
                            <h4 className='contact-form-title'>Kérjen ajánlatot!</h4>
                            <ContactForm />
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0, x: -100 }}
                            whileInView={{ x: 0, opacity: 1 }}
                            viewport={{ once: true }}
                            transition={{ ease: "easeOut", duration: .7 }}
                            className='contact__contacts'
                        >
                            <h1 className='contact__contacts-title'>Elérhetőségek</h1>
                            <div className='contact__contacts-email-box'>
                                <span className="material-symbols-rounded contact__contacts-email-icon">
                                    mail
                                </span>
                                <a className='contact__contacts-email' href="mailto:mondiasztalos@gmail.com">mondiasztalos@gmail.com</a>
                            </div>
                            <div className='contact__contacts-number-box '>
                                <span className="material-symbols-rounded contact__contacts-number-icon">
                                    call
                                </span>
                                <a className='contact__contacts-number' href="tel:+36306995241">+36 30 699 5241</a>
                            </div>
                            <div className='contact__contacts-schedule-box'>
                                <span className="material-symbols-rounded contact__contacts-schedule-icon">
                                    calendar_clock
                                </span>
                                <p className='contact__contacts-schedule'>Hétköznap 08:00 - 20:00</p>
                            </div>
                        </motion.div>
                    </div>
                </div>
            </div>
            <Copyright />
        </div>
    )
}

export default Contact