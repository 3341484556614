import React, { useState } from 'react';
import './Navbar.css'
import { Link } from 'react-router-dom';

const Navbar = () => {
    const [isActive, setIsActive] = useState(false);

    const toggleActive = () => {
        setIsActive(!isActive);
    };

    return (
        <div className='nav__bar'>
            <div className='nav__container'>
                <Link className='logo' to="/">
                    <svg id="White" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1080 1080">
                        <path className="cls-1" d="M20,0C8.95,0,0,8.95,0,20v329.81h382.37V0H20ZM339.36,204.8c0,5.62-4.56,10.17-10.18,10.17-2.81,0-5.35-1.14-7.19-2.98s-2.98-4.38-2.98-7.19v-59.79c0-5.62,4.55-10.18,10.17-10.18,2.81,0,5.36,1.14,7.2,2.98,1.84,1.85,2.98,4.39,2.98,7.2v59.79h0Z" />
                        <path className="cls-1" d="M595.27,355.93v349.82h202.92l40.23,96.15c3.37,8.47,12.97,12.59,21.44,9.21,8.46-3.37,12.58-12.97,9.21-21.44l-26.04-83.92h114.61c11.05,0,20-8.96,20-20v-329.82h-382.37ZM657.63,560.73c0,2.81-1.14,5.35-2.98,7.2-1.84,1.84-4.38,2.98-7.19,2.98-5.62,0-10.18-4.56-10.18-10.18v-59.78c0-2.81,1.14-5.35,2.98-7.2,1.84-1.84,4.39-2.98,7.2-2.98,5.62,0,10.17,4.56,10.17,10.18v59.78h0Z" />
                        <path className="cls-1" d="M588.21,475.79v229.96H179.13l-40.23,96.15c-3.38,8.47-12.97,12.59-21.44,9.21-8.46-3.37-12.59-12.97-9.21-21.44l26.04-83.92H20C8.95,705.75,0,696.79,0,685.75v-329.82h67.13v119.86h29.14v-28.22l-2.82-57.85,29.3,86.07h19.26l29.22-85.99-2.83,57.77v28.22h29.22v-119.86h50.6c-3.61,1.18-7.04,2.76-10.3,4.73-8.1,4.93-14.33,11.91-18.67,20.96-4.35,9.05-6.52,19.5-6.52,31.34v6.8c.22,11.41,2.56,21.5,7.01,30.3,4.46,8.8,10.67,15.57,18.64,20.3,7.97,4.73,17.1,7.09,27.39,7.09s19.47-2.44,27.52-7.34,14.26-11.88,18.63-20.96c4.38-9.08,6.56-19.51,6.56-31.29v-5.57c-.05-11.62-2.31-21.92-6.76-30.92-4.46-8.99-10.7-15.92-18.72-20.79-3.2-1.94-6.57-3.49-10.12-4.65h50.63v119.86h29.13v-75.2l42.83,75.2h28.97v-119.86h18.43v119.86h39.76c10.46-.05,19.94-2.55,28.43-7.51,8.5-4.95,15.08-11.81,19.76-20.58,4.67-8.77,7.01-18.69,7.01-29.76v-5.56c-.05-10.96-2.47-20.85-7.26-29.68-4.79-8.82-11.44-15.66-19.96-20.5-5.38-3.05-11.19-5.14-17.43-6.27h61.59v119.86h23.44,0Z" />
                        <polygon className="cls-1" points="405.39 355.93 405.39 430.14 363.2 355.93 405.39 355.93" />
                        <polygon className="cls-1" points="158.95 355.93 132.38 439.02 105.72 355.93 158.95 355.93" />
                        <path className="cls-1" d="M288.85,412.79v5.56c-.06,11.74-2.08,20.73-6.06,26.98-3.99,6.25-9.66,9.38-17.02,9.38-7.75,0-13.58-3.18-17.51-9.55-3.93-6.36-5.9-15.46-5.9-27.3l.09-8.89c.83-21.91,8.55-32.87,23.16-32.87,7.47,0,13.21,3.12,17.22,9.34,4.01,6.23,6.02,15.34,6.02,27.35h0Z" />
                        <path className="cls-1" d="M518.11,412.71v5.23c0,11.73-2.24,20.57-6.72,26.52s-10.9,8.92-19.26,8.92h-10.12v-75.95h9.79c8.58,0,15.11,2.96,19.59,8.88s6.72,14.72,6.72,26.4h0Z" />
                        <g>
                            <polygon className="cls-1" points="894.74 349.81 859.04 349.81 859.04 309.87 874.81 309.87 894.74 349.81" />
                            <path className="cls-1" d="M894.15,270.9c0,5.22-1.4,9.28-4.19,12.19-2.8,2.91-6.96,4.37-12.5,4.37h-18.42v-33.29h18.42c5.48,0,9.63,1.49,12.46,4.46,2.82,2.96,4.23,7.06,4.23,12.27h0Z" />
                            <path className="cls-1" d="M449.64,316.43c0,4.37-1.41,7.76-4.23,10.17s-6.86,3.61-12.12,3.61h-19.09v-28.97h20.42c10.01.05,15.02,5.12,15.02,15.19Z" />
                            <path className="cls-1" d="M447.07,268.58c0,4.44-1.36,7.8-4.07,10.07-2.71,2.28-7.25,3.44-13.61,3.5h-15.19v-27.98h14.27c6.53,0,11.27,1.13,14.2,3.38,2.93,2.24,4.4,5.92,4.4,11.03h0Z" />
                            <path className="cls-1" d="M785.24,289.62v5.56c-.05,11.73-2.07,20.72-6.06,26.98-3.98,6.25-9.65,9.38-17.01,9.38-7.75,0-13.59-3.18-17.52-9.55-3.93-6.36-5.89-15.47-5.89-27.31l.08-8.88c.83-21.91,8.55-32.87,23.16-32.87,7.47,0,13.21,3.11,17.22,9.34,4.02,6.22,6.02,15.34,6.02,27.35h0Z" />
                            <path className="cls-1" d="M957.64,0H389.43v231.76h39.04c15.55,0,27.38,2.85,35.49,8.55s12.16,13.98,12.16,24.82c0,6.25-1.44,11.59-4.32,16.02-2.87,4.43-7.11,7.69-12.7,9.8,6.31,1.66,11.16,4.76,14.53,9.29,3.38,4.54,5.06,10.08,5.06,16.6,0,11.85-3.75,20.74-11.24,26.69-3.39,2.69-7.53,4.79-12.41,6.28h63.06c-4.06-1.79-7.68-4.12-10.85-6.98-8.47-7.64-12.78-18.1-12.95-31.38v-79.69h29.3v80.27c.33,13.23,6.48,19.84,18.43,19.84,6.03,0,10.6-1.66,13.7-4.98,3.09-3.32,4.64-8.72,4.64-16.19v-78.94h29.14v79.11c0,8.96-1.91,16.72-5.73,23.28s-9.29,11.55-16.43,14.98c-.49.24-.99.47-1.49.68h71.3v-95.55h-35.61v-22.5h101.02v22.5h-36.28v95.55h73.59c-1.75-.77-3.46-1.65-5.11-2.63-7.97-4.73-14.18-11.49-18.63-20.29-4.46-8.8-6.79-18.9-7.02-30.3v-6.81c0-11.84,2.17-22.28,6.52-31.33,4.34-9.05,10.57-16.03,18.68-20.96,8.1-4.93,17.33-7.39,27.68-7.39s19.37,2.44,27.39,7.31c8.03,4.87,14.27,11.8,18.72,20.79,4.45,8.99,6.71,19.3,6.77,30.92v5.56c0,11.79-2.19,22.22-6.56,31.3-4.37,9.07-10.59,16.06-18.64,20.96-1.77,1.08-3.6,2.04-5.49,2.87h45.71v-118.05h47.56c14.34,0,25.54,3.18,33.62,9.54,8.08,6.36,12.12,15.34,12.12,26.95,0,8.4-1.7,15.36-5.1,20.89-3.41,5.53-8.73,10.01-15.98,13.44l24.42,47.23h51.1V20c0-11.05-8.95-20-20-20h0ZM551.58,225.45l-22.83-.04,16.35-26.02h29.55l-23.07,26.06h0Z" />
                        </g>
                    </svg></Link>
                <Link className='nav__title' to="/">Mondi Bútor</Link>
                <div className='nav__links-h'>
                    <Link className='nav__link-h' to="/">Főoldal</Link>
                    <Link className='nav__link-h' to="/services">Szolgáltaltások</Link>
                    <Link className='nav__link-h' to="/reference">Referenciák</Link>
                    <Link className='nav__link-h' to="/contact">Kapcsolatok</Link>
                    <Link className='nav__link-h-button' to="/contact">Ajánlatkérés</Link>
                </div>
                <div className={`nav__button ${isActive ? 'active' : ''}`} onClick={toggleActive}>
                    <div className='nav__line'></div>
                    <div className='nav__line'></div>
                    <div className='nav__line'></div>
                </div>
            </div>
            <div className={`nav__links-v ${isActive ? 'active' : ''}`}>
                <Link className='nav__link-v' onClick={toggleActive} to="/">Főoldal</Link>
                <Link className='nav__link-v' onClick={toggleActive} to="/services">Szolgáltaltások</Link>
                <Link className='nav__link-v' onClick={toggleActive} to="/reference">Referenciák</Link>
                <Link className='nav__link-v' onClick={toggleActive} to="/contact">Kapcsolatok</Link>
            </div>
        </div >
    )
}

export default Navbar