import React from 'react'
import { motion } from 'framer-motion';
import './ServicePicture.css'


const ServicePicture = ({ img, animation }) => {
    return (
        <motion.div
            initial={{ opacity: 0, x: animation }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ ease: "easeOut", duration: .5 }}
            className='service__section-img'
        >
            <img src={img} alt="Service 1" />
        </motion.div>
    )
}

export default ServicePicture