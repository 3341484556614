import React, { useState, useEffect } from 'react';
import Navbar from '../../components/navbar/Navbar';
import Copyright from '../../components/copyright/Copyright';
import ReferencePictureBox from '../../components/referencepicturebox/ReferencePictureBox';
import PageUp from '../../components/pageup/PageUp';
import './Reference.css';
import { motion } from 'framer-motion';
import { referencehero } from '../../assets/img';
import { storage } from '../../firebase';
import { getDownloadURL, listAll, ref } from 'firebase/storage';


const Reference = () => {

    const useImageList = (storageRef) => {
        const [images, setImages] = useState([]);
        useEffect(() => {
            listAll(storageRef).then((res) => {
                Promise.all(res.items.map((item) => getDownloadURL(item))).then((urls) => {
                    setImages(urls);
                });
            });
        }, []);
        return images;
    };

    const kitchenImagesRef = ref(storage, 'imgs/kitchen');
    const kitchenImages = useImageList(kitchenImagesRef);

    const wardrobeImagesRef = ref(storage, 'imgs/wardrobes');
    const wardrobeImages = useImageList(wardrobeImagesRef);

    const closetsImagesRef = ref(storage, 'imgs/closet');
    const closetsImages = useImageList(closetsImagesRef);

    const bedImagesRef = ref(storage, 'imgs/bed');
    const bedImages = useImageList(bedImagesRef);

    const bedroomImagesRef = ref(storage, 'imgs/bedroom');
    const bedroomImages = useImageList(bedroomImagesRef);

    const bathroomImagesRef = ref(storage, 'imgs/bathroom');
    const bathroomImages = useImageList(bathroomImagesRef);

    const livingroomImagesRef = ref(storage, 'imgs/livingroom');
    const livingroomImages = useImageList(livingroomImagesRef);

    const tableImagesRef = ref(storage, 'imgs/table');
    const tableImages = useImageList(tableImagesRef);

    const officeImagesRef = ref(storage, 'imgs/office');
    const officeImages = useImageList(officeImagesRef);

    const otherImagesRef = ref(storage, 'imgs/other');
    const otherImages = useImageList(otherImagesRef);


    const [activeFilter, setActiveFilter] = useState('All');
    const [clickedReference, setClickedReference] = useState(null);

    useEffect(() => {
        document.body.style.overflow = clickedReference !== null ? 'hidden' : 'auto';
    }, [clickedReference]);

    const handleFilterClick = (filter) => {
        setActiveFilter(filter);
    };

    const handleReferenceClick = (reference) => {
        setClickedReference(reference);
    };

    const close = () => {
        setClickedReference(null);
    };

    const container = {
        hidden: { opacity: 0 },
        show: {
            opacity: 1,
            transition: {
                staggerChildren: .08
            }
        }
    }
    const item = {
        hidden: { opacity: 0 },
        show: { opacity: 1 }
    }

    const backgroundStyle = {
        background: `url(${referencehero}) center/cover no-repeat`,
        height: '100%'
    };

    return (
        <>
            {clickedReference && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: .1 }}
                    className='clicked-reference-container'
                >
                    <div className='clicked-reference-box'>
                        <motion.img
                            initial={{ opacity: 0, scale: 0 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: .3 }}
                            src={clickedReference}
                            alt={clickedReference}
                            className='clicked-reference-img' />
                        <button onClick={close} className='clicked-reference-button'>
                            <span className="material-symbols-outlined">
                                close
                            </span>
                        </button>
                    </div>
                </motion.div>
            )}

            <div className='reference__container' style={clickedReference && { filter: 'blur(4px)' }}>
                <div style={backgroundStyle}>
                    <div className='reference__nav-box'>
                        <Navbar />
                        <div className='reference__title'>
                            <motion.h1
                                initial={{ opacity: 0, y: -30 }}
                                whileInView={{ y: 0, opacity: 1 }}
                                viewport={{ once: true }}
                                transition={{ ease: "easeOut", duration: .7 }}
                            >Referenciák</motion.h1>
                        </div>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>

                            {/* Buttons */}
                            <motion.div
                                initial="hidden"
                                whileInView='show'
                                viewport={{ once: true }}
                                variants={container}
                                className='reference__filter-buttons'>
                                <motion.button
                                    variants={item}
                                    className={`reference__filter-button ${activeFilter === 'All' ? 'active' : ''}`}
                                    onClick={() => handleFilterClick('All')}
                                >
                                    Összes
                                </motion.button>
                                <motion.button
                                    variants={item}
                                    className={`reference__filter-button ${activeFilter === 'Kitchen' ? 'active' : ''}`}
                                    onClick={() => handleFilterClick('Kitchen')}
                                >
                                    Konyhabútorok
                                </motion.button>
                                <motion.button
                                    variants={item}
                                    className={`reference__filter-button ${activeFilter === 'Wardrobes' ? 'active' : ''}`}
                                    onClick={() => handleFilterClick('Wardrobes')}
                                >
                                    Beépített szekrények
                                </motion.button>
                                <motion.button
                                    variants={item}
                                    className={`reference__filter-button ${activeFilter === 'Closets' ? 'active' : ''}`}
                                    onClick={() => handleFilterClick('Closets')}
                                >
                                    Gardrób szekrények
                                </motion.button>
                                <motion.button
                                    variants={item}
                                    className={`reference__filter-button ${activeFilter === 'Beds' ? 'active' : ''}`}
                                    onClick={() => handleFilterClick('Beds')}
                                >
                                    Ágyak
                                </motion.button>
                                <motion.button
                                    variants={item}
                                    className={`reference__filter-button ${activeFilter === 'Bedroom' ? 'active' : ''}`}
                                    onClick={() => handleFilterClick('Bedroom')}
                                >
                                    Hálószoba bútor
                                </motion.button>
                                <motion.button
                                    variants={item}
                                    className={`reference__filter-button ${activeFilter === 'Bathroom' ? 'active' : ''}`}
                                    onClick={() => handleFilterClick('Bathroom')}
                                >
                                    Fürdőszoba bútor
                                </motion.button>
                                <motion.button
                                    variants={item}
                                    className={`reference__filter-button ${activeFilter === 'LivingRoom' ? 'active' : ''}`}
                                    onClick={() => handleFilterClick('LivingRoom')}
                                >
                                    Nappali bútor
                                </motion.button>
                                <motion.button
                                    variants={item}
                                    className={`reference__filter-button ${activeFilter === 'Table' ? 'active' : ''}`}
                                    onClick={() => handleFilterClick('Table')}
                                >
                                    Asztalok
                                </motion.button>
                                <motion.button
                                    variants={item}
                                    className={`reference__filter-button ${activeFilter === 'Office' ? 'active' : ''}`}
                                    onClick={() => handleFilterClick('Office')}
                                >
                                    Iroda
                                </motion.button>
                                <motion.button
                                    variants={item}
                                    className={`reference__filter-button ${activeFilter === 'Other' ? 'active' : ''}`}
                                    onClick={() => handleFilterClick('Other')}
                                >
                                    Egyéb
                                </motion.button>
                            </motion.div>
                        </div>
                    </div>
                </div>

                <div className='reference__photo-container'>
                    {activeFilter === 'All' && (
                        <motion.div
                            className='reference__photo-all'>

                            {/* Kitchen */}
                            <ReferencePictureBox type={kitchenImages} title='Konyhabútor' onReferenceBox={handleReferenceClick} />

                            {/* Wardrobes */}
                            <ReferencePictureBox type={wardrobeImages} title='Beépített szekrények' onReferenceBox={handleReferenceClick} />

                            {/* Closets */}
                            <ReferencePictureBox type={closetsImages} title='Gardrób szekrények' onReferenceBox={handleReferenceClick} />

                            {/* Beds */}
                            <ReferencePictureBox type={bedImages} title='Ágyak' onReferenceBox={handleReferenceClick} />

                            {/* Bedrooms */}
                            <ReferencePictureBox type={bedroomImages} title='Hálószoba bútor' onReferenceBox={handleReferenceClick} />

                            {/* Bathrooms */}
                            <ReferencePictureBox type={bathroomImages} title='Fürdőszoba bútor' onReferenceBox={handleReferenceClick} />

                            {/* LivingRooms */}
                            <ReferencePictureBox type={livingroomImages} title='Nappali bútor' onReferenceBox={handleReferenceClick} />

                            {/* Tables */}
                            <ReferencePictureBox type={tableImages} title='Asztalok' onReferenceBox={handleReferenceClick} />

                            {/* Offices */}
                            <ReferencePictureBox type={officeImages} title='Idoda' onReferenceBox={handleReferenceClick} />

                            {/* Other */}
                            <ReferencePictureBox type={otherImages} title='Egyéb' onReferenceBox={handleReferenceClick} />

                        </motion.div>
                    )}
                    {activeFilter === 'Kitchen' && (
                        <>
                            {/* Kitchen */}
                            <ReferencePictureBox type={kitchenImages} title='Konyhabútor' onReferenceBox={handleReferenceClick} />
                        </>
                    )}
                    {activeFilter === 'Wardrobes' && (
                        <>
                            {/* Wardrobes */}
                            <ReferencePictureBox type={wardrobeImages} title='Beépített szekrények' onReferenceBox={handleReferenceClick} />
                        </>
                    )}
                    {activeFilter === 'Closets' && (
                        <>
                            {/* Closets */}
                            <ReferencePictureBox type={closetsImages} title='Gardrób szekrények' onReferenceBox={handleReferenceClick} />
                        </>
                    )}
                    {activeFilter === 'Beds' && (
                        <>
                            {/* Beds */}
                            <ReferencePictureBox type={bedImages} title='Ágyak' onReferenceBox={handleReferenceClick} />
                        </>
                    )}
                    {activeFilter === 'Bedroom' && (
                        <>
                            {/* Bedrooms */}
                            <ReferencePictureBox type={bedroomImages} title='Hálószoba bútor' onReferenceBox={handleReferenceClick} />
                        </>
                    )}
                    {activeFilter === 'Bathroom' && (
                        <>
                            {/* Bathrooms */}
                            <ReferencePictureBox type={bathroomImages} title='Fürdőszoba bútor' onReferenceBox={handleReferenceClick} />
                        </>
                    )}
                    {activeFilter === 'LivingRoom' && (
                        <>
                            {/* LivingRooms */}
                            <ReferencePictureBox type={livingroomImages} title='Nappali bútor' onReferenceBox={handleReferenceClick} />
                        </>
                    )}
                    {activeFilter === 'Table' && (
                        <>
                            {/* Tables */}
                            <ReferencePictureBox type={tableImages} title='Asztalok' onReferenceBox={handleReferenceClick} />

                        </>
                    )}
                    {activeFilter === 'Office' && (
                        <>
                            {/* Offices */}
                            <ReferencePictureBox type={officeImages} title='Idoda' onReferenceBox={handleReferenceClick} />
                        </>
                    )}
                    {activeFilter === 'Other' && (
                        <>
                            {/* Other */}
                            <ReferencePictureBox type={otherImages} title='Egyéb' onReferenceBox={handleReferenceClick} />
                        </>
                    )}
                </div>
                <PageUp />
                <Copyright />
            </div >
        </>
    )
}

export default Reference