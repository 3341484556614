import React from 'react'
import './Home.css'
import Navbar from '../../components/navbar/Navbar'
import Copyright from '../../components/copyright/Copyright'
import ContactForm from '../../components/contactform/ContantForm'
import PageUp from '../../components/pageup/PageUp';
import { motion } from 'framer-motion';
import {
    homehero, homehero2, service1, service2, service3, service4,
} from '../../assets/img';


const Home = () => {

    const container = {
        hidden: { opacity: 0, y: -30 },
        show: {
            y: 0,
            opacity: 1,
            transition: {
                ease: "easeOut", duration: .7,
                staggerChildren: .2
            }
        }
    }
    const item = {
        hidden: { opacity: 0 },
        show: { opacity: 1 }
    }
    const backgroundStyle = {
        background: `url(${homehero}) center/cover no-repeat`,
        minHeight: '100vh'
    };

    return (
        <div className='home__container'>
            <div style={backgroundStyle}>
                <div style={{ background: 'rgba(49, 49, 49, .75)', minHeight: '100vh', overflow: 'hidden' }}>
                    <Navbar />
                    <div className='home__hero-box'>
                        <motion.div
                            initial="hidden"
                            whileInView='show'
                            viewport={{ once: true }}
                            variants={container}
                            className='home__title-box'
                        >
                            <motion.h4
                                variants={item}
                                className='home__subtitle'
                            >
                                Mondi Bence Bútorasztalos - Ön megálmodja, én elkészítem!</motion.h4>
                            <motion.h1
                                variants={item}
                                className='home__title'
                            >
                                Egyedi méretű bútorok tervezése és kivitelezése.</motion.h1>
                            <motion.p
                                variants={item}
                                className='home__p'
                            >
                                Több éves tapasztalattal készítek egyedi méretre szabott konyhabútorokat, gardróbszekrényeket, előszoba bútorokat és fürdőszoba bútorokat minőségi alapanyagok felhasználásával. Teljes körű szolgáltatást biztosítok ügyfeleimnek.</motion.p>
                            <motion.a
                                variants={item}
                                className='home__service-button'
                                href="/services"
                            >
                                Szolgáltaltások</motion.a>
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0, x: 100 }}
                            whileInView={{ x: 0, opacity: 1 }}
                            viewport={{ once: true }}
                            transition={{ ease: "easeOut", duration: .7 }}
                            className='home__contact-container'
                        >
                            <div className='home__contact-box'>
                                <h4 className='home__contact-title'>Kérjen ajánlatot!</h4>
                                <ContactForm />
                            </div>
                            <div className='home__contact-pn-box'>
                                <h4>Hívjon bizalommal!</h4>
                                <a className='home__mobile-n' href="tel:+36306995241">+36 30 699 5241</a>
                            </div>
                        </motion.div>
                    </div>
                </div>
            </div>

            <div className='home__intoduction-container'>
                <div className='home__intoduction-box'>
                    <motion.div
                        initial="hidden"
                        whileInView='show'
                        viewport={{ once: true }}
                        variants={container}
                    >
                        <motion.h1
                            variants={item}
                            className='home__intoduction-title'
                        >
                            Bemutatkozás</motion.h1>
                        <motion.h2
                            variants={item}
                            className='home__intoduction-subtitle'
                        >
                            Alapelveim közé tartozik a megbízhatóság, a pontosság és az igényesség.
                        </motion.h2>
                        <motion.p
                            variants={item}
                            className='home__intoduction-text'
                        >
                            Mondi Bence vagyok, asztalos szakember Az évek folyamán több céggel, és vállalkozással működtem együtt, melynek köszönhetően jelentős tapasztalattal rendelkezem a tömör fa, és a bútorlapok megmunkálása terén is. Célom az időtálló és egyedi bútorok megalkotása, amelyek tökéletesen kielégítik az Ön igényeit. Legyen szó bármilyen bútortípusról, – számíthat rám már a tervezéstől, egészen a kivitelezésig.
                        </motion.p>
                    </motion.div>
                    <motion.div
                        initial={{ opacity: 0, x: 100 }}
                        whileInView={{ x: 0, opacity: 1 }}
                        viewport={{ once: true }}
                        transition={{ ease: "easeOut", duration: .7 }}
                        className='home__intoduction-img'
                    >
                        <img src={service1} alt="dsd" />
                    </motion.div>
                </div>
            </div>


            <div className='home__reference-section'
                style={{ background: `url(${homehero2}) center/cover no-repeat` }}>
                <div className='home__reference-container'>
                    <motion.div
                        initial="hidden"
                        whileInView='show'
                        viewport={{ once: true }}
                        variants={container}
                        className='home__reference-box'
                    >
                        <motion.h1
                            variants={item}
                            className='home__reference-title'
                        >
                            Megbízható, és minőségi asztalos munkák egy helyen
                        </motion.h1>
                        <motion.p
                            variants={item}
                            className='home__reference-text'
                        >
                            Egyedi bútorok felmérése, tervezése, és kivitelezése. Alkalmazkodom az Ön igényeihez és egyedi elképzeléseihez.
                        </motion.p>
                        <motion.a
                            variants={item}
                            className='home__reference-button'
                            href="/reference"
                        >
                            Referenciák</motion.a>
                    </motion.div>
                </div>
            </div>


            <div className='home__service-container'>
                <motion.div
                    initial="hidden"
                    whileInView='show'
                    viewport={{ once: true }}
                    variants={container}
                    className='home__service-box'
                >
                    <div>
                        <motion.h1
                            variants={item}
                            className='home__service-title'
                        >
                            Szolgáltatások</motion.h1>
                        <motion.p
                            variants={item}
                            className='home__service-text'
                        >
                            A felmérés, tervezés, és kivitelezés mellett a helyszínen összeszerelt vagy lapraszerelt bútorokat is összeállítom és szakmai tanácsokkal segítem az ügyfeleimet.</motion.p>
                    </div>
                    <motion.div
                        initial="hidden"
                        whileInView='show'
                        viewport={{ once: true }}
                        variants={container}
                        className='home__service-grid'
                    >
                        <motion.div
                            variants={item}
                            className='home__service-grid-item'
                        >
                            <img src={service1} alt="kép1" />
                            <h3 className='home__service-grid-title'>
                                Konyhabútor</h3>
                            <p className='home__service-grid-text'>Konyhabútorok teljes körű felmérését, tervezését és kivitelezését vállalom, hogy tökéletesen megfeleljenek az Ön ízlésének és igényeinek.</p>
                        </motion.div>
                        <motion.div
                            variants={item}
                            className='home__service-grid-item'
                        >
                            <img src={service2} alt="kép1" />
                            <h3 className='home__service-grid-title'>
                                Gardrób szekrény</h3>
                            <p className='home__service-grid-text'>Egyedi gardrób szekrények felmérését, tervezését és kivitelezését vállalom, hogy a legoptimálisabb megoldásokat nyújtsam.</p>
                        </motion.div>
                        <motion.div
                            variants={item}
                            className='home__service-grid-item'
                        >
                            <img src={service3} alt="kép1" />
                            <h3 className='home__service-grid-title'>
                                Előszoba szekrény</h3>
                            <p className='home__service-grid-text'>Előszoba szekrények tervezése és kivitelezése a terek optimális kihasználásával, hogy praktikus és esztétikus megoldásokat biztosítsak az Ön számára.</p></motion.div>
                        <motion.div
                            variants={item}
                            className='home__service-grid-item'
                        >
                            <img src={service4} alt="kép1" />
                            <h3 className='home__service-grid-title'>
                                Fürdőszoba szekrény</h3>
                            <p className='home__service-grid-text'>Fürdőszoba szekrények teljes körű felmérését, tervezését és kivitelezését vállalom.</p>
                        </motion.div>
                    </motion.div>
                    <motion.div
                        variants={item}
                        className='home__service-button2-box'
                    >
                        <a className='home__service-button2'
                            href="/services"
                        >Szolgáltaltások
                        </a>
                    </motion.div>

                </motion.div>
            </div>
            <PageUp />
            <Copyright />
        </div >
    )
}

export default Home