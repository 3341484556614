import axios from 'axios';
import React, { useState, useEffect } from 'react';
import './ContactForm.css';

const ContantForm = () => {
    const [isInView, setIsInView] = useState(true);

    // Function to handle scroll events
    const handleScroll = () => {
        const formElement = document.querySelector('.home__form');
        if (formElement) {
            const rect = formElement.getBoundingClientRect();
            // Update isInView based on whether the form is in the viewport
            setIsInView(rect.top < window.innerHeight && rect.bottom >= 0);
        }
    };

    useEffect(() => {
        // Add scroll event listener when the component mounts
        window.addEventListener('scroll', handleScroll);
        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Update error state when the component goes out of view
    useEffect(() => {
        if (!isInView) {
            setError(false);
        }
    }, [isInView]);

    const placeholderText = '30 699 5241';
    const fakenumber = '20 63 524';
    const [inputValue, setInputValue] = useState('');

    const handleChange = (e) => {
        const filteredValue = e.target.value.replace(/[^0-9]/g, '');

        const truncatedValue = filteredValue.substring(0, fakenumber.length);

        const formattedValue = truncatedValue
            .split('')
            .map((char, index) =>
                fakenumber[index] === ' ' ? ` ${char}` : char
            )
            .join('');

        setInputValue(formattedValue);
        setphoneNumber(formattedValue === '' ? '' : `+36 ${formattedValue}`);

    };


    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setphoneNumber] = useState('');
    const [selectedService, setSelectedService] = useState('');
    const [message, setMessage] = useState('');
    const [selectNC, setSelectNC] = useState('');
    const [selectColor, setSelectColor] = useState('var(--d-font)');
    const [error, setError] = useState(false);
    const [isShaky, setIsShaky] = useState(false);
    const [buttonmessage, setButtonMessage] = useState('Üzenet küldés');
    const [submitButtonBG, setSubmitButtonBG] = useState('Üzenet küldés');

    useEffect(() => {
        if (!phoneNumber.trim()) {
            setSelectNC('');
        } else {
            setSelectNC('var(--d-font)');
        }
    }, [phoneNumber]);

    useEffect(() => {
        if (selectedService === 'Konyhabútor' || selectedService === 'Gardrób szekrény' || selectedService === 'Előszoba szekrény' || selectedService === 'Fürdőszoba szekrény' || selectedService === 'Egyéb') {
            setSelectColor('var(--d-font)');
        } else {
            setSelectColor('rgb(169, 169, 169)');
        }
    }, [selectedService]);


    const validateInput = (value) => {
        if (!value.trim()) {
            setError(true)
            setIsShaky(true);
            setTimeout(() => {
                setIsShaky(false);
            }, 1000);
            setSubmitButtonBG('unsuccessful')
            setButtonMessage('Küldés sikertelen');
            setTimeout(() => {
                setSubmitButtonBG('')
                setButtonMessage('Üzenet küldés');
            }, 1000);
            return false;
        }
        setError(false)
        setIsShaky(false)
        return true;
    };

    const nameInput = `home__name ${error && name.trim() === '' ? 'error' : ''}${isShaky && name.trim() === '' ? ' shaky' : ''}`;

    const emailInput = `home__email ${error && email.trim() === '' ? 'error' : ''}${isShaky && email.trim() === '' ? ' shaky' : ''}`;

    const phoneNInput = `home__phonenumber-box ${error && phoneNumber.trim() === '' ? 'error' : ''}${isShaky && phoneNumber.trim() === '' ? ' shaky' : ''}`;

    const serviceInput = `home__select-box ${error && selectedService.trim() === '' ? 'error' : ''}${isShaky && selectedService.trim() === '' ? ' shaky' : ''}`;

    const messageInput = `home__text ${error && message.trim() === '' ? 'error' : ''}${isShaky && message.trim() === '' ? ' shaky' : ''}`;

    const submitButton = `home__submit ${submitButtonBG}`;


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateInput(name) || !validateInput(email) || !validateInput(phoneNumber) || !validateInput(selectedService) || !validateInput(message)) {
            // If any validation fails, return from the function
            return;
        }
        // Your EmailJS service ID, template ID, and Public Key
        const serviceId = process.env.REACT_APP_EMAIJS_SERVICE_ID;
        const templateId = process.env.REACT_APP_EMAIJS_TEMPLATE_ID;
        const publicKey = process.env.REACT_APP_EMAIJS_PUBLIC_KEY;

        // Create an object with EmailJS service ID, template ID, Public Key, and Template params
        const data = {
            service_id: serviceId,
            template_id: templateId,
            user_id: publicKey,
            template_params: {
                from_name: name,
                from_email: email,
                from_phoneN: phoneNumber,
                from_service: selectedService,
                from_message: message,
            }
        };
        setButtonMessage('Küldés folyamtban');
        // Send the email using EmailJS
        try {
            const res = await axios.post("https://api.emailjs.com/api/v1.0/email/send", data);
            console.log(res.data);
            setName('');
            setEmail('');
            setInputValue('');
            setphoneNumber('');
            setSelectedService('');
            setMessage('');
            setSubmitButtonBG('successful')
            setButtonMessage('Elküldve');
            setTimeout(() => {
                setSubmitButtonBG('')
                setButtonMessage('Üzenet küldés');
            }, 1500);
        } catch (error) {
            setSubmitButtonBG('unsuccessful')
            setButtonMessage('Küldés sikertelen');
            setTimeout(() => {
                setSubmitButtonBG('')
                setButtonMessage('Üzenet küldés');
            }, 2000);
            console.error(error);
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit} className='home__form'>
                <input
                    className={nameInput}
                    type="text"
                    placeholder='Név'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <input
                    className={emailInput}
                    type="email"
                    placeholder='Email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <div className={phoneNInput}>
                    <label
                        htmlFor="phoneNumber"
                        style={{ color: selectNC }}
                        className='home__phonenumber-label'>+36</label>
                    <input
                        type="text"
                        id="phoneNumber"
                        placeholder={placeholderText}
                        value={inputValue}
                        onChange={handleChange}
                        className='home__phonenumber-input'
                    />
                </div>
                <div className={serviceInput}>
                    <select
                        name=""
                        id=""
                        value={selectedService}
                        onChange={(e) => setSelectedService(e.target.value)}
                        style={{ color: selectColor }}
                        className='home__select'
                    >
                        <option value="" disabled hidden >Válassz egy szolgáltatást</option>
                        <option className='home__option' value="Konyhabútor">Konyhabútor</option>
                        < option className='home__option' value="Gardrób szekrény" >Gardrób szekrény</option>
                        <option className='home__option' value="Előszoba szekrény">Előszoba szekrény</option>
                        <option className='home__option' value="Fürdőszoba szekrény">Fürdőszoba szekrény</option>
                        <option className='home__option' value="Egyéb">Egyéb</option>
                    </select>
                    <span className="material-symbols-outlined">
                        expand_more
                    </span>
                </div>

                <textarea
                    className={messageInput}
                    name=""
                    id=""
                    cols="30" rows="10"
                    placeholder='Üzenet'
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                ></textarea>
                <button className={submitButton} type='submit'>{buttonmessage}</button>
            </form ></>
    )
}

export default ContantForm