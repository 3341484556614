import './App.css';
import Home from './pages/Home/Home';
import Services from './pages/Services/Services';
import Reference from './pages/Reference/Reference';
import Contact from './pages/Contact/Contact';
import Error from './pages/Error/Error';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/reference" element={<Reference />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<Error />} />
      </Routes>
    </Router>
  );
}

export default App;
