import React from 'react';
import './ReferencePicture.css';
import { motion } from 'framer-motion';


const ReferencePicture = ({ img, onReferenceClick }) => {


    return (
        <motion.div
            whileHover={{ scale: 1.07 }}
            whileTap={{ scale: 0.95 }}
            className='reference__img-box'
            onClick={() => onReferenceClick && onReferenceClick(`${img}`)}
        >
            <div className='sekeletonloader'>
                <span className="material-symbols-outlined">
                    image
                </span>
            </div>
            <img className='reference__img' loading='lazy' src={img} alt='' />
        </motion.div>
    )

}

export default ReferencePicture