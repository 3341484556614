import React from 'react';
import { motion } from 'framer-motion';
import ReferencePicture from '../../components/referencepicture/ReferencePicture';
import './ReferenceictureBox.css'


const ReferencePictureBox = ({ type, title, onReferenceBox }) => {


    return (
        <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className='reference__picture-container'>
            <motion.h1
                className='reference__photo-title'
                initial={{ y: 50 }}
                whileInView={{ y: 7 }}
                viewport={{ once: true }}
                transition={{ ease: "easeOut", duration: .3 }}
            >
                {title}
            </motion.h1>
            <div className='reference__picture-box'>
                {type.map((img, index) => (
                    <ReferencePicture key={index} img={img} onReferenceClick={onReferenceBox} />
                ))}
            </div>

        </motion.div>
    )
}

export default ReferencePictureBox