import React, { useState, useEffect } from 'react';
import './PageUp.css';
import { motion } from "framer-motion"

const PageUp = () => {


    const [heigh, setHeigh] = useState(500);
    const [isVisible, setIsVisible] = useState({ opacity: 0, y: 100 });

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            if (scrollTop > heigh) {
                setIsVisible({ opacity: 1, y: 0 });
            } else {
                setIsVisible({ opacity: 0, y: 100 });
            }
        };

        const handleResize = () => {
            if (window.innerWidth < 500) {
                setHeigh(700);
            } else {
                setHeigh(500);
            }
        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);

        // Cleanup
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, [heigh]);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };


    return (
        <>
            <motion.div
                initial={{ opacity: 0, y: 100 }}
                animate={isVisible}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                onClick={scrollToTop}
                className='pageup_div'
            >
                <span className="material-symbols-outlined pageup_icon ">
                    expand_less
                </span>
            </motion.div>
        </>
    );
};


export default PageUp