import React from 'react'
import './ServiceText.css'
import { motion } from 'framer-motion';

const ServiceText = ({ title, text, animation }) => {
    return (
        <motion.div
            initial={{ opacity: 0, x: animation }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ ease: "easeOut", duration: .5 }}>
            <h2 className='service__section-title'>{title}</h2>
            <p className='service__section-text'>{text}</p>
        </motion.div>
    )
}

export default ServiceText