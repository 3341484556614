import React from 'react';
import Navbar from '../../components/navbar/Navbar';
import Copyright from '../../components/copyright/Copyright';
import ServiceText from '../../components/servicetext/ServiceText';
import ServicePicture from '../../components/servicepicture/ServicePicture';
import PageUp from '../../components/pageup/PageUp';
import { motion } from 'framer-motion';
import './Services.css'
import {
    service1, service2, service3, service4, service5, servicehero
} from '../../assets/img';



const Services = () => {

    const backgroundStyle = {
        background: `url(${servicehero}) center/cover no-repeat`,
        height: '100%'
    };

    return (
        <div className='service__container'>
            <div style={backgroundStyle}>
                <div className='service__nav-box'>
                    <Navbar />
                    <div className='service__title'>
                        <motion.h1
                            initial={{ opacity: 0, y: -30 }}
                            whileInView={{ y: 0, opacity: 1 }}
                            viewport={{ once: true }}
                            transition={{ ease: "easeOut", duration: .7 }}
                        >
                            Szolgáltatások
                        </motion.h1>
                    </div>
                </div>
            </div>
            <div className='service__sections'>
                <section className='service__section'>
                    <ServicePicture img={service1} animation='-100' />
                    <ServiceText animation='200' title='Konyhabútor' text='A konyhabútor tervezése, kivitelezése és összeszerelése az egyik legfontosabb, hiszen a konyha az otthon szíve, és nagy jelentőséggel bír a mindennapokban. Egyedi igényekre szabott tervezéssel és minőségi alapanyagokkal dolgozom, hogy olyan konyhabútort készítsek, amely tartós és esztétikus lesz akár több generáción át. A gyártás mellett a helyszíni felmérést, és a tervezést is vállalom.' />
                </section>

                <div className='service__line'></div>

                <section className='service__section'>
                    <ServicePicture img={service2} animation='100' />
                    <ServiceText animation='-200' title='Gardrób szekrény' text='Gardrób szekrények tervezése és kivitelezése során nagy figyelmet fordítok a funkcionalitásra és az esztétikumra egyaránt, hogy a végeredmény maximálisan megfeleljen az Ön igényeinek. Minőségi alapanyagokkal dolgozom az időtállóság érdekében, hogy a gardrób szekrény hosszú éveken keresztül kiszolgálja tulajdonosait. Minden gardrób szekrényt az adott helyszín méreteire igazítok, így optimálisan kihasználjuk a rendelkezésre álló teret.' />
                </section>

                <div className='service__line'></div>

                <section className='service__section'>
                    <ServicePicture img={service3} animation='-100' />
                    <ServiceText animation='200' title='Előszoba szekrény' text='Az előszoba teremti meg az első benyomást, amikor a vendégek megérkeznek otthonába, ezért fontos, hogy praktikus és stílusos berendezéssel rendelkezzen. Vállalom az előszoba szekrények teljeskörű tervezését és kivitelezését, hogy a rend és a funkcionalitás egyszerre jelenjen meg otthonában. Az előszoba szekrények kialakítása során figyelembe veszem az Ön egyedi igényeit és elképzeléseit, így garantálom a maximális elégedettséget.'
                    />
                </section>

                <div className='service__line'></div>

                <section className='service__section'>
                    <ServicePicture img={service4} animation='100' />
                    <ServiceText animation='-200' title='Fürdőszoba szekrény' text='A fürdőszoba szekrények kialakítása és elhelyezése nagyban meghatározza az adott helyiség hangulatát, ezért fontos, hogy a legjobb megoldásokat válasszuk. Egyedi tervezéssel és minőségi alapanyagok felhasználásával szép és tartós fürdőszoba szekrényeket készíthetünk. A kivitelezés mellett vállalom a szekrények gyors és precíz összeszerelését is a helyszínen, hogy minél előbb használatba vehesse új fürdőszoba bútorait.'
                    />

                </section>

                <div className='service__line'></div>

                <section className='service__section'>
                    <ServicePicture img={service5} animation='-100' />
                    <ServiceText animation='200' title='Egyéb asztalos munkák' text='Konkrét elképzelése van? Egyedi elképzelések alapján készített bútorok tervezését és kivitelezését is vállalom, legyen az asztal, szék vagy bármilyen különleges bútor. Ha bármilyen egyedi igénye van, akár kisebb javítási munkákról van szó, forduljon hozzám bizalommal! Szakmai tanácsadást is biztosítok, hogy az elképzelései és az igényei alapján a legmegfelelőbb megoldást találjuk meg, és az elkészült munka hosszú évekig szép és használható maradjon.'
                    />
                </section>
            </div>
            <PageUp />
            <Copyright />
        </div>
    )
}

export default Services