import React from 'react';
import './Copyright.css';
import { motion } from 'framer-motion';

const Copyright = () => {
    const currentYear = new Date().getFullYear();

    return (
        <div className='copyright__container'>
            <motion.p
                initial={{ opacity: 0, y: -10 }}
                whileInView={{ y: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{ ease: "easeOut", duration: .5 }}
            >Copyright © <span>{currentYear}</span> MBWood</motion.p>
        </div>
    );
}

export default Copyright;
